<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="title">{{ objDepartment.name }}</div>
        <div class="">{{ objDepartment.address }}</div>
      </v-col>
      <v-col>
        <order-form :services="this.orderData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "store/names";
import OrderForm from "components/partials/OrderForm";
export default {
  name: "DepartmentCardFast",
  components: { OrderForm },
  data: () => ({
    orderData: [],
  }),
  computed: {
    ...mapGetters({
      objDepartment: names.OBJ_DEPARTMENT_INFO,
    }),
  },
  mounted() {
    this.collectOrderData();
  },
  methods: {
    collectOrderData() {
      this.orderData = {
        departmentName: this.objDepartment.name,

        departmentId: this.objDepartment.departmentId,

        departmentAddress: this.objDepartment.address,

        departmentPhone: this.objDepartment.phones[0].phone,

        serviceName: null,

        doctorName: null,

        doctorServicePrice: null,

        servicePrice: null,
      };
    },
  },
};
</script>

<style scoped></style>
